<template>
  <div class="login">
    <Header :loginState="loginState" />
    <div class="input-bg">
      <!-- 添加银行卡组件 -->
      <add-bank-data :showModal="showModal"/>
      <LoginFooter />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LoginFooter from "@/components/LoginFooter.vue";
import AddBankData from '@/components/AddBankData.vue';
export default {
  name: "AddBankCard",
  components: {
    Header,
    LoginFooter,
    AddBankData
  },
  data(){
    return {
      loginState:2,
      showModal:true,
    }
  },
  created() {
    
  },
};
</script>
<style>
@import url("../assets/css/public.css");
.perfect-data-modal{height:628px;margin-top: 70px;}
@media screen and (max-width: 768px) {
  .perfect-data-modal{height:100%;margin-top: 3rem;}
}  
</style>
