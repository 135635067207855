<template>
  <!-- pc端底部 -->
  <div class="footer hide-pc">
    <div class="footer-box">
     <a :href="links1">{{$t('footer.navText1')}}</a><a href="">|</a>
     <a :href="links2">{{$t('footer.navText2')}}</a><a href="">|</a>
     <a :href="links3">{{$t('footer.navText3')}}</a><a href="">|</a>
     <a :href="links4">{{$t('footer.navText4')}}</a><a href="">|</a>
     <a :href="links5">{{$t('footer.navText5')}}</a><a href="">|</a>
     <a :href="links6">{{$t('footer.navText6')}}</a><a href="">|</a>
     <a :href="links7">{{$t('footer.navText7')}}</a>
    </div>    
    <p class="footer-text">{{$t('footer.copyright')}}</p>
  </div>
  <!-- m端底部 -->
  <div class="footer hide-m">
    <div class="footer-box">
     <a :href="links2">{{$t('footer.navText2')}}</a><a href="">|</a>
     <a :href="links5">{{$t('footer.navText5')}}</a>
    </div>    
    <p class="footer-text">{{$t('footer.copyright')}}</p>
  </div>
  <!-- 右侧悬浮 -->
  <div v-if="hideState" class="right-side hide-pc">
      <div class="right-side-box">
          <img @click="closeBtn()" class="close-btn" src="../assets/images/register/gb-icon.png">
          <img class="app-code" src="../assets/images/register/right-code.png">
          <a class="btn-list" href="/mobileReg" target="_blank">
              <img class="btn-icon" src="../assets/images/register/btn-icon1.png">
              <span class="btn-text">{{$t('footer.suText1')}}</span>
          </a>
          <a class="btn-list" :href="serviceLink" target="_blank">
              <img class="btn-icon" src="../assets/images/register/btn-icon2.png">
              <span class="btn-text">{{$t('footer.suText2')}}</span>
          </a>
      </div>
  </div>
</template>

<script>
import { getCookie } from '@/util/cookie';
export default{
  data() {
    return {
      links1:'',
      links2:'',
      links3:'',
      links4:'',
      links5:'',
      links6:'',
      links7:'',
      serviceLink:'',
      hideState:true,
    }
  },
  created() {
    let lang = getCookie('LOCALE');
      if(lang == "zh_CN"){
          lang = "ch";
      }else if(lang == "zh_TW"){
          lang = "tw"
      }else if(lang == "en_US"){
          lang = "en"
      }else if(lang == "id_ID"){
          lang = "id"
      }else if(lang == "th_TH"){
          lang = "th"
      }else if(lang == "hi_IN"){
          lang = "hi"
      }

    //动态域名链接
    // let domain= window.location.host.split(".").length > 2 ? window.location.host.split(".")[1]+"."+window.location.host.split(".")[2] : window.location.host ;
    // this.links1 = window.location.protocol +"//www."+domain+"/"+lang+"/about";
    // this.links2 = window.location.protocol +"//www."+domain+"/"+lang+"/agreement";
    // this.links3 = window.location.protocol +"//www."+domain+"/"+lang+"/disclaim";
    // this.links4 = window.location.protocol +"//www."+domain+"/"+lang+"/risk";
    // this.links5 = window.location.protocol +"//www."+domain+"/"+lang+"/declare";
    // this.links6 = window.location.protocol +"//www."+domain+"/"+lang+"/safety";
    // this.links7 = window.location.protocol +"//www."+domain+"/"+lang+"/help-center";

    //写死域名链接
    this.links1 = window.location.protocol +"//www.cmtrade.com/"+lang+"/about";
    this.links2 = window.location.protocol +"//www.cmtrade.com/"+lang+"/agreement";
    this.links3 = window.location.protocol +"//www.cmtrade.com/"+lang+"/disclaim";
    this.links4 = window.location.protocol +"//www.cmtrade.com/"+lang+"/risk";
    this.links5 = window.location.protocol +"//www.cmtrade.com/"+lang+"/declare";
    this.links6 = window.location.protocol +"//www.cmtrade.com/"+lang+"/safety";
    this.links7 = window.location.protocol +"//www.cmtrade.com/"+lang+"/help-center";

    let lang1 = getCookie('LOCALE');
    if(lang1 == "zh_CN"){
          lang1 = "zh";
      }else if(lang1 == "zh_TW"){
          lang1 = "zh_TW"
      }else if(lang1 == "en_US"){
          lang1 = "en"
      }else if(lang1 == "id_ID"){
          lang1 = "in"
      }else if(lang1 == "th_TH"){
          lang1 = "th"
      }else if(lang1 == "hi_IN"){
          lang1 = "hi"
      }
    this.serviceLink = "https://chat10.live800.com/live800/chatClient/chatbox.jsp?companyID=1524200&configID=239203&jid=5772502639&s=1&lan="+lang1;
  },
  methods: {
    closeBtn(){
      this.hideState = false;
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .footer{margin-top: 45px;}
  .footer .footer-box{width: 1032px;margin: auto; margin-bottom: 18px; display: flex;flex-direction: row;justify-content: space-around;align-items: center;}
  .footer .footer-box a{font-size: 16px;color: #f2f2f2;}
  .footer .footer-text{font-size: 16px;color: #f2f2f2;text-align: center;}
  /* 右侧悬浮栏 */
  .right-side{width: 168px;padding-bottom: 30px; position: fixed;right:0;top:50%;margin-top: -208px;z-index:100; background: url(../assets/images/register/right-bg.png) no-repeat;background-size: 100% 100%;}
  .right-side-box{position: relative;display: flex;flex-direction: column;justify-content: flex-start;align-items: center;}
  .right-side-box .close-btn{width: 10px;height:10px;position: absolute;right: 15px;top:55px;display: block;cursor: pointer;}
  .right-side-box .app-code{width: 118px;height:119px;margin-top: 164px;margin-bottom: 5px;}
  .right-side-box .btn-list{width: 98px;padding:6px 8px;margin-top: 12px;background: #ffd94c; border-radius: 5px; display: flex;flex-direction: row;justify-content: center;align-items: center;}
  .right-side-box .btn-list .btn-icon{width: 16px;height: 13px;margin-right: 10px;}
  .right-side-box .btn-list .btn-text{font-size: 14px;width: 78px;color: #525252;}
  .right-side-box .btn-list:hover{background: #efcb47;}

  @media screen and (max-width: 768px) {
  .footer{margin-top: 3rem;width: 100%; margin-bottom: 2rem;}
  .footer .footer-box{width: 90%;margin: auto; margin-bottom: 1rem; display: flex;flex-direction: row;justify-content: space-around;align-items: center;}
  .footer .footer-box a{font-size: 0.7rem;color: #666;}
  .footer .footer-text{width: 90%; font-size: 0.6rem;color: #666;text-align: center;margin: auto;}
  }
</style>
